import Timeline from './components/Timeline';
import './App.css';

function App() {
  return (
    <div class="timelineContainer">
      <Timeline></Timeline>
    </div>
  );
}

export default App;
