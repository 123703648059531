import styles from './css/MostZoomedInPro.module.css';
import { getMonth, getMonthDay } from '../utils/index';


const MostZoomedInPro = (props) => {
    let startMonth, startDay, endMonth, endDay;
    startMonth = getMonth(props.startDate);
    startDay = getMonthDay(props.startDate);
    endMonth = getMonth(props.dueDate);
    endDay = getMonthDay(props.dueDate);
    return (
        <div className={`${styles.mostZoomedInPro}`} style={{ backgroundColor: props.backgroundColor, width: `calc((((100% / 12) * ${endMonth}) + (((100% / 12) / 30) * ${endDay})) - (((100% / 12) * ${startMonth}) + (((100% / 12) / 30) * ${startDay})))`, left: `calc((((100% / 12) * ${startMonth}) + (((100% / 12) / 30) * ${startDay})))`, top: `calc(${props.found} * 60px)` }}>
            <div className={`${styles.mostZoomedInProInner}`}>
                <div className={styles.projectDetails}>
                    <div className={styles.title}>
                        <p>{props.title}</p>
                    </div>
                    <div className={styles.description}>
                        <p>{props.text}</p>
                    </div>
                </div>
                <div className={styles.projectManager}>
                    <div className={styles.image} style={{backgroundImage: `url(${props.imageUrl})`}}>
                    </div>
                    <div className={styles.name}>
                        <p>{props.title}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MostZoomedInPro;