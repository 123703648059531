import styles from './css/NormalPro.module.css';
import { getMonth, getMonthDay } from '../utils/index';

const NormalPro = (props) => {
    let startMonth, startDay, endMonth, endDay;
    startMonth = getMonth(props.startDate);
    startDay = getMonthDay(props.startDate);
    endMonth = getMonth(props.dueDate);
    endDay = getMonthDay(props.dueDate);
    return (
        <div className={`${styles.normalPro}`} style={{ backgroundColor: props.backgroundColor, width: `calc((((100% / 12) * ${endMonth}) + (((100% / 12) / 30) * ${endDay})) - (((100% / 12) * ${startMonth}) + (((100% / 12) / 30) * ${startDay})))`, left: `calc((((100% / 12) * ${startMonth}) + (((100% / 12) / 30) * ${startDay})))`, top: `calc(${props.found} * 60px)` }}>
            <div className={styles.normalProInner}>
                <div className={styles.title}>
                    {props.title}
                </div>
            </div>
        </div>
    )
}

export default NormalPro;