
export const getMonth = (date) => {
    let d, month;
    if (date) {
        d = new Date(date);
        month = d.getMonth();
    } else {
        month = -1;
    }

    return month;
}


export const getMonthDay = (date) => {
    let d, day;
    if (date) {
        d = new Date(date);
        day = d.getDate();
    } else {
        day = -1;
    }

    return day;
}


/*
export function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
    if (b_start <  a_start && a_end   <  b_end) return true; // a in b
    return false;
}

*/


export function dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
    aStart = new Date(aStart);
    aEnd = new Date(aEnd);
    bStart = new Date(bStart);
    bEnd = new Date(bEnd);
    if ((bStart > aStart && bStart < aEnd) && (bEnd > aStart && bEnd < aEnd)) {
        return true;
    } else if (bStart > aStart && bStart < aEnd) {
        return true;
    } else if (bEnd > aStart && bEnd < aEnd) {
        return true;
    } else if (aStart < bStart && aEnd > bEnd) {
        return true;
    } else if (aStart.getMonth() == bStart.getMonth() && aEnd.getMonth() == bEnd.getMonth()) {
        return true;
    }

    return false;
}

