import React, { Component } from 'react'
import styles from './css/Timeline.module.css';

import projectJson from './projectData.json';
import NormalPro from './NormalPro';
import BitZoomedInPro from './BitZoomedInPro';
import MostZoomedInPro from './MostZoomedInPro';
import MostZoomedOutPro from './MostZoomedOutPro';

import { ExpandLess, ExpandMore, Add, Remove, Close, Forward, Backward } from './Svgs';
import { getMonth, getMonthDay, dateRangeOverlaps } from '../utils/index';


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";



export class Timeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: 0,
            selectedYear: 0,
            zoomValue: '1',
            listOfYears: [],
            listOfMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            projectData: projectJson,
            zoomFactor: projectJson.zoomFactor,
            displayListOfYears: false,
            thisYearProjectData: [],
            xDragging: true,
            yDragging: true,
            disablePan: false,
            lockAxisY: false,
            lockAxisX: false,
            isMonthsListOverflowing: false,
            forwardBackwardStep: 200,
        }
    }

    componentDidMount() {
        let currentYear, selectedYear, listOfYears = [];
        currentYear = (new Date()).getFullYear();
        selectedYear = currentYear;

        for (let i = 0; i < 30; i++) {
            listOfYears.push(currentYear - i);
        }


        this.setState({
            listOfYears: listOfYears,
            currentYear: currentYear,
        })

        this.selectYear(currentYear);
    }




    moveMonthsForward = (rest, setPositionX) => {
        let monthsList;
        if ((rest.scale * 800 - 800) > Math.abs(rest.positionX)) {
            monthsList = document.getElementsByClassName('monthsList')[0];
            monthsList.style.transform = `translate(${rest.positionX - this.state.forwardBackwardStep}px, 0px) scaleX(${rest.scale})`;
            setPositionX(rest.positionX - this.state.forwardBackwardStep);
        }
    }

    moveMonthsBackward = (rest, setPositionX) => {
        let monthsList;
        monthsList = document.getElementsByClassName('monthsList')[0];
        if (rest.positionX >= 0) {

        } else {
            monthsList.style.transform = `translate(${rest.positionX + this.state.forwardBackwardStep}px, 0px) scaleX(${rest.scale})`;
            setPositionX(rest.positionX + this.state.forwardBackwardStep);
        }
    }


    selectYear = (year) => {
        let thisYearProjectData = [];
        thisYearProjectData = this.state.projectData.projects.filter((project) => {
            let startYear;
            startYear = (new Date(project.startDate)).getFullYear();
            if (startYear == year) {
                return project;
            }
        });


        this.setState({
            selectedYear: year,
            thisYearProjectData: thisYearProjectData
        })
    }


    setDisplayListOfYears = () => {
        this.setState({
            displayListOfYears: !this.state.displayListOfYears
        })
    }

    setZoomValue = (e, setScale, setPositionX, setPositionY) => {
        setScale(e.target.value);
    }


    onPanning = (rest) => {

        if (rest.positionX > 5 || rest.positionY > 5) {
            this.setState({
                lockAxisY: true,
                lockAxisX: true,
            })
        } else {
            this.setState({
                lockAxisY: false,
                lockAxisX: false,
            })
        }

        if ((rest.scale * 800 - 800) > Math.abs(rest.positionX)) {
            let monthsList;
            monthsList = document.getElementsByClassName('monthsList')[0];
            monthsList.style.transform = `translate(${rest.positionX}px, 0px) scaleX(${rest.scale})`;
        }
    }

    onZoomChange = (rest) => {
        let monthsList, timelineMonth;
        monthsList = document.getElementsByClassName('monthsList')[0];
        monthsList.style.transform = `translate(${rest.positionX}px, 0px) scaleX(${rest.scale})`;

        for (let i=0; i<monthsList.getElementsByTagName('div').length; i++) {
            monthsList.getElementsByTagName('div')[i].style.transform = `scaleX(${1/rest.scale})`;
            document.getElementsByClassName('dottedLine')[i].style.transform = `scaleX(${1/rest.scale})`;
        }
        
        document.getElementsByClassName('lineDate')[0].style.transform = `scaleX(${1/rest.scale})`;
    }



    render() {
        const projectData = this.state.projectData;
        return (
            <div className={styles.timelineContainer}>
                <TransformWrapper
                    zoomIn={{ step: 10 * (this.state.zoomFactor) }}
                    zoomOut={{ step: 10 * (this.state.zoomFactor) }}
                    doubleClick={{ step: 10 * (this.state.zoomFactor) }}
                    pan={{ velocityEqualToMove: true, lockAxisX: this.state.lockAxisX, lockAxisY: this.state.lockAxisY, panReturnAnimationTime: 100 }}
                    onPanning={this.onPanning}
                    onZoomChange={this.onZoomChange}
                >

                    {
                        ({ zoomIn, zoomOut, resetTransform, setScale, setPositionX, setPositionY, ...rest }) => {

                            return (
                                <React.Fragment>
                                    <div className={styles.tools}>
                                        <button onClick={zoomIn}><Add></Add></button>
                                        <button onClick={zoomOut}><Remove></Remove></button>
                                        <button onClick={resetTransform}><Close></Close></button>
                                    </div>

                                    {
                                        /*
                                        JSON.stringify(rest)
                                        */
                                    }
                                    <div className={styles.timeline}>
                                        <div className={styles.timelineHeader}>
                                            <div className={styles.timelineHeaderInner}>
                                                <div className={styles.zoomHandler}>
                                                    <div className={styles.zoomText}>
                                                        <span>Zoom</span>
                                                    </div>
                                                    <div className={styles.zoomInput}>
                                                        <input type="range" min="1" max="8" defaultValue="1" value={rest.zoomValue} onChange={(e) => this.setZoomValue(e, setScale, setPositionX, setPositionY)} />
                                                    </div>
                                                </div>
                                                <div className={styles.yearsDropdown} onClick={this.setDisplayListOfYears}>
                                                    <input type="text" readOnly={true} value={this.state.selectedYear} />
                                                    <span className={styles.arrowIcon}>
                                                        {
                                                            this.state.displayListOfYears ?
                                                                <ExpandLess></ExpandLess> :
                                                                <ExpandMore></ExpandMore>
                                                        }
                                                    </span>
                                                    {
                                                        this.state.displayListOfYears ?
                                                            <div className={styles.listOfYears}>
                                                                <ul>
                                                                    {
                                                                        this.state.listOfYears.map((year, index) => (
                                                                            <li key={index} onClick={() => this.selectYear(year)}>{year}</li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div> :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>





                                        <div className={styles.timelineBody}>
                                            <div className={styles.timelineBodyInner}>
                                                <TransformComponent>
                                                    <div className={`projectionDataLayout ${styles.projectionDataLayout}`}>
                                                        <BackgroundTimelineOverlay
                                                            listOfMonths={this.state.listOfMonths}
                                                            lineDate={projectData.lineDate}
                                                        >
                                                        </BackgroundTimelineOverlay>
                                                        {
                                                            this.state.thisYearProjectData.map((project, index) => {
                                                                let totalProjects = 0, doneProjects = 0, found = 0, overlap, otherProject;


                                                                for (let i = 0; i < index; i++) {

                                                                    otherProject = this.state.thisYearProjectData[i];

                                                                    overlap = dateRangeOverlaps(project.startDate, project.dueDate, otherProject.startDate, otherProject.dueDate);

                                                                    if (overlap) {
                                                                        found++;
                                                                    }
                                                                }

                                                                if (rest.scale >= '1' && rest.scale <= '1.5') {
                                                                    return (<MostZoomedOutPro
                                                                        {...project}
                                                                        found={found}
                                                                    >
                                                                    </MostZoomedOutPro>)
                                                                } else if (rest.scale > '1.5' && rest.scale <= '2') {
                                                                    return (<NormalPro
                                                                        {...project}
                                                                        found={found}
                                                                    >
                                                                    </NormalPro>)
                                                                } else if (rest.scale > '2' && rest.scale <= '2.5') {
                                                                    return (<BitZoomedInPro
                                                                        {...project}
                                                                        found={found}
                                                                    >
                                                                    </BitZoomedInPro>)
                                                                } else if (rest.scale > '2.5') {
                                                                    return (<MostZoomedInPro
                                                                        {...project}
                                                                        found={found}
                                                                        doneProjects={doneProjects}
                                                                        totalProjects={totalProjects}
                                                                    >
                                                                    </MostZoomedInPro>)
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </TransformComponent>
                                                <div className={`monthsListContainer ${styles.monthsListContainer}`}>
                                                    <MonthsTimeline
                                                        listOfMonths={this.state.listOfMonths}
                                                    ></MonthsTimeline>

                                                    <span className={styles.forwardMonth} onClick={() => this.moveMonthsForward(rest, setPositionX)}>
                                                        <Forward></Forward>
                                                    </span>
                                                    <span className={styles.backwardMonth} onClick={() => this.moveMonthsBackward(rest, setPositionX)}>
                                                        <Backward></Backward>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    }
                </TransformWrapper>
            </div>
        )
    }
}





const BackgroundTimelineOverlay = (props) => {
    let month, day, today;
    today = new Date();
    month = today.getMonth();
    day = today.getDate();
    /*
    month = getMonth(props.lineDate);
    day = getMonthDay(props.lineDate);
    */

    return (
        <div className={styles.backgroundTimelineOverlay}>
            {
                props.listOfMonths.map((value, index) => (
                    <div className={`timelineMonth ${styles.timelineMonth}`} key={index}>
                        <span className={`dottedLine ${styles.dottedLine}`}></span>
                    </div>
                ))
            }

            <div className={`lineDate ${styles.lineDate}`} style={{ left: `calc((((100% / 12) * ${month}) + (((100% / 12) / 30) * ${day})))`, transform: 'none' }}>
            </div>
        </div>
    )
}




function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}


const MonthsTimeline = (props) => {
    return (
        <div className={`monthsList ${styles.monthsList}`}>
            {
                props.listOfMonths.map((value, index) => (
                    <div className={styles.monthValue} key={index}>{value}</div>
                ))
            }
        </div>
    )
}



export default Timeline
